import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector  } from "react-redux";
import { setPrinter, selectPrinter } from "../../../app/Redux-slices/printerSlice";

const ThermalPrinter = () => {
  let data = localStorage.getItem("epsonPrinter") !== null ? JSON.parse(localStorage.getItem("epsonPrinter")) : null
  const [printerIPAddress, setPrinterIPAddress] = useState(data !== null ? data.address : "");
  const [printerPort, setPrinterPort] = useState(data !== null ? data.port : "");
  const [textToPrint, setTextToPrint] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");

  const ePosDevice = useRef();
  const printer = useRef();

  const setPrinterReduce = setPrinter()
  const dispatch = useDispatch()

  const printerReducer = useSelector(selectPrinter).printer

  const STATUS_CONNECTED = "Connected";

  /**
   * Connect to a printer
   */
  const connect = () => {
    console.log(printerIPAddress)
    if (!printerIPAddress) {
      setConnectionStatus("Veuillez entrer l'adresse IP de l'imprimante !");
      return;
    }
    if (!printerPort) {
      setConnectionStatus("Veuillez entrer le port de l'imprimante ! ");
      return;
    }

    setConnectionStatus("En cours de connexion ... ");

    console.log('Start connecting ...')

    let ePosDev = new window.epson.ePOSDevice(); // EPSON object for the current device

    console.log("ePosDev==========================")
    console.log(ePosDev)
    ePosDevice.current = ePosDev;

    ePosDev.connect(printerIPAddress, printerPort, (data) => { // Connect to the device
      console.log("======DATA===================")
      console.log(data)
      if (data === "SSL_CONNECT_OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: false, buffer: false },
          (devobj, retcode) => {
            if (retcode === "OK") {
              dispatch(setPrinter(devobj))
              console.log("printer=========================")
              console.log(printerReducer)
              printer.current = devobj;
              localStorage.setItem("epsonPrinter", JSON.stringify({ address: printerIPAddress, port: printerPort }));
              setConnectionStatus(STATUS_CONNECTED);
            } else {
              throw retcode;
            }
          }
        );
      } else {
        console.log("=========ELSE============")
        console.log(data)
        setConnectionStatus("La connexion a échoué");
        throw data;
      }
    });
  };

  // Delete printer
  function disconnect() {
    localStorage.removeItem("epsonPrinter")
    setConnectionStatus("")
  }


  /**
   * Print something or alert if there is no printer
   * @param {*} text 
   * @returns 
   */
  const print = (text) => {
    let prn = printer.current;
    if (!prn) {
      alert("Not connected to printer");
      return;
    }

    //prn.addText(text);
    //prn.addFeedLine(5);
    //prn.addCut(prn.CUT_FEED);

    prn.addTextFont(prn.FONT_A);
    prn.addTextDouble(true, true);
    prn.addText('MARKUS\n');
    prn.addTextFont(prn.FONT_A);
    prn.addTextDouble(false, false);
    prn.addText('Hello\n');
    prn.addFeedLine(5);
    prn.addCut(prn.CUT_FEED);
    prn.send();

  };

  useEffect(() => {
    if (localStorage.getItem("epsonPrinter") !== null && printerReducer[0].printer === null) {
      console.log("THERMAL PRINTER============================1")
      console.log(printerReducer[0].printer)
      connect()
    } else if(data !== null){
      console.log("THERMAL PRINTER============================2")
      console.log(printerReducer[0].printer)
      printer.current = printerReducer[0].printer
      setConnectionStatus(STATUS_CONNECTED)
    }
  }, [])

  return (
    <div id="thermalPrinter">
      <input
        id="printerIPAddress"
        placeholder="Printer IP Address"
        value={printerIPAddress}
        onChange={(e) => setPrinterIPAddress(e.currentTarget.value)}
      />
      <input
        id="printerPort"
        placeholder="Printer Port"
        value={printerPort}
        onChange={(e) => setPrinterPort(e.currentTarget.value)}
      />
      <button
        disabled={connectionStatus === STATUS_CONNECTED}
        onClick={() => connect()}
      >
        Se connecter
      </button>

      <button
        onClick={() => disconnect()}
      >
        Supprimer printer
      </button>

      <br />
      <br />

      <br />
      <span className="status-label">{connectionStatus}</span>
      <hr />
      <textarea
        id="textToPrint"
        rows="3"
        placeholder="Entrer un text à imprimer"
        value={textToPrint}
        onChange={(e) => setTextToPrint(e.currentTarget.value)}
      />
      <button
        //disabled={connectionStatus !== STATUS_CONNECTED}
        onClick={() => print(textToPrint)
        }
      >
        Print
      </button>
    </div>
  );
};

/**
   * Print something or alert if there is no printer
   * @param {*} text 
   * @returns 
   */
export const printf = (text, printer) => {
  let prn = printer;
  if (!prn) {
    alert("Not connected to printer");
    return;
  }

  console.log("J'imprime !==================")
  console.log(text)

  // ============== HEADER
  prn.addTextAlign(printer.ALIGN_CENTER);
  prn.addTextFont(printer.FONT_A);
  prn.addTextDouble(true, true);
  prn.addTextSmooth(true);
  prn.addTextSize(3, 3);
  prn.addText('MARKUS DASHBOARD\n\n');
  prn.addTextSmooth(false);
  prn.addTextSize(2, 2);
  prn.addText(`REFERENCE : ${text.commandReference} \n\n`)

  // =========== BODY
  prn.addTextAlign(printer.ALIGN_LEFT);
  prn.addTextFont(printer.FONT_A);
  prn.addTextSize(1, 1);
  prn.addTextSmooth(true);
  prn.addText(`Date de commande : ${text.commandDate}\n`);
  prn.addText(`Client : ${text.client}\n\n`);
  prn.addText(text.clientAddress + ',\n')
  prn.addText(text.clientZipCode + ' ' + text.clientCity + '\n')
  prn.addText(text.clientPhoneNumber + '\n')
  prn.addTextSmooth(false);

  prn.addTextAlign(printer.ALIGN_CENTER);
  prn.addTextFont(printer.FONT_A);
  prn.addTextDouble(true, true);
  prn.addTextSize(1, 1);
  prn.addText("=".repeat(42) + '\n')
  prn.addTextSize(3, 3);
  prn.addText(text.commandMethod + '\n');
  prn.addTextSize(1, 1);
  prn.addText("=".repeat(42) + '\n')

  if (text.comment !== '') {
    prn.addTextAlign(prn.ALIGN_CENTER);
    prn.addTextFont(prn.FONT_A);
    prn.addTextDouble(true, true);
    prn.addTextSize(1, 1);
    prn.addTextAlign(prn.ALIGN_LEFT);
    prn.addText("Commentaire : ")
    prn.addTextSmooth(true);
    prn.addText(text.comment + '\n');
    prn.addTextAlign(prn.ALIGN_CENTER);
    prn.addTextSmooth(false);
    prn.addText("=".repeat(42) + '\n')
  }

  prn.addTextAlign(printer.ALIGN_LEFT);
  prn.addTextFont(printer.FONT_A);
  prn.addTextSize(1, 1);

  // Products and ingredients
  text.products.map((product) => {
    if (product !== null) {
      prn.addTextFont(prn.FONT_B);
      prn.addTextSize(2, 2);

      let nomProduit = product.quantite + "x" + product.produit.nom
      // 15, 21
      prn.addText("\n•" +nomProduit).addText((" ".repeat((24 - (nomProduit).length))) + product.produit.prix + "€\n");

      let supplements = JSON.parse(product.supplements)
      if (supplements.pain !== null) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSmooth(true);
        prn.addTextSize(1, 1);
        prn.addText("\nPain : \n")
        prn.addTextSmooth(false);

        prn.addTextFont(prn.FONT_B);
        prn.addTextDouble(false, false);
        prn.addTextSize(2, 2);
        prn.addTextPosition(50);
        prn.addText(supplements.pain + '\n');
        prn.addTextVPosition(50);
        prn.addTextPosition(0);
      }

      if (supplements.garniture.length > 0) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nGarniture(s) : \n")
        prn.addTextSmooth(false);

        supplements.garniture.map((x) => {
          prn.addTextFont(prn.FONT_B);
          prn.addTextDouble(false, false);
          prn.addTextSize(2, 2);
          prn.addTextPosition(50);
          prn.addText(x + '\n');
          prn.addTextVPosition(50);
          prn.addTextPosition(0);
        })
      }

      if (supplements.sauce.length > 0) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nSauce(s) : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        supplements.sauce.map((sauce) => {
          prn.addTextDouble(false, false);
          prn.addTextSize(2, 2);
          prn.addTextPosition(50);
          prn.addText(sauce + '\n');
          prn.addTextVPosition(50);
          prn.addTextPosition(0);
        })
      }

      if (supplements.viande_1_selected !== null) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nViande : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        prn.addTextDouble(false, false);
        prn.addTextSize(2, 2);
        prn.addTextPosition(50);
        prn.addText(supplements.viande_1_selected + '\n');
        prn.addTextVPosition(50);
        prn.addTextPosition(0);
      }

      if (supplements.viande_2_selected.length > 0) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nViandes : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        supplements.viande_2_selected.map((x) => {
          prn.addTextDouble(false, false);
          prn.addTextSize(2, 2);
          prn.addTextPosition(50);
          prn.addText(x + '\n');
          prn.addTextVPosition(50);
          prn.addTextPosition(0);
        })
      }

      if (supplements.viande_3_selected && supplements.viande_3_selected.length > 0) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nViandes : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        supplements.viande_3_selected.map((x) => {
          prn.addTextDouble(false, false);
          prn.addTextSize(2, 2);
          prn.addTextPosition(50);
          prn.addText(x + '\n');
          prn.addTextVPosition(50);
          prn.addTextPosition(0);
        })
      }

      if (supplements.supplements.length > 0) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nSuppléments : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        supplements.supplements.map((x) => {
          const supp = JSON.parse(x)
          prn.addTextDouble(false, false);
          prn.addTextSize(2, 2);
          prn.addTextPosition(50);
          prn.addText(supp.nom_supplement + " " + supp.prix_supplement + '€\n');
          prn.addTextVPosition(50);
          prn.addTextPosition(0);
        })
      }

      if (supplements.boisson !== null) {
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextDouble(true, true);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nBoisson : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        prn.addTextDouble(false, false);
        prn.addTextSize(2, 2);
        prn.addTextPosition(50);
        prn.addText(supplements.boisson + '\n');
        prn.addTextVPosition(50);
        prn.addTextPosition(0);
      }

      if (product.information !== '') {
        prn.addTextDouble(true, true);
        prn.addTextAlign(prn.ALIGN_LEFT);
        prn.addTextFont(prn.FONT_A);
        prn.addTextSize(1, 1);
        prn.addTextSmooth(true);
        prn.addText("\nInformation : \n")
        prn.addTextSmooth(false);
        prn.addTextFont(prn.FONT_B);

        prn.addTextDouble(false, false);
        prn.addTextSize(2, 2);
        prn.addTextPosition(50);
        prn.addText(product.information + '\n\n');
        prn.addTextVPosition(50);
        prn.addTextPosition(0);
      }

    }
  })

  prn.addTextVPosition(60);
  prn.addTextAlign(printer.ALIGN_CENTER);
  prn.addText("-------------------------------");
  prn.addTextAlign(printer.ALIGN_LEFT);
  prn.addText(`\n\nTotal (ttc) : ${text.totalPrice}€\n`);

  prn.addFeedLine(5);
  prn.addCut(prn.CUT_FEED);
  prn.send();

  //commande_est_vue(text.id)
};

export default ThermalPrinter;