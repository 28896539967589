import axios from "axios";
import { useEffect, useState /*, useRef*/ } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrinter, selectPrinter } from "../../../app/Redux-slices/printerSlice";

import ThermalPrinter from "./ThermalPrinter"


const Printer = () => {

  return (
    <div className="nouvelleCommande admin__container">
      <h1
        style={{
          textAlign: "center",
          marginBottom: "2rem",
          color: "#04295d",
        }}
      >
        Configurer votre imprimante Epson
      </h1>

      <ThermalPrinter />

    </div>
  );
};

export default Printer;
